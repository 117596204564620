<template>
    <div class="form-container">
        <div class="form-header">
            <img src="@/assets/icons/earth.svg" alt="Xperience Icon" class="form-icon" />
            <h2 class="form-title">XPERIENCE</h2>
        </div>
        <form @submit.prevent="handleSubmit">
            <div>
                <label for="date">Date</label>
                <input type="date" v-model="formData.date" id="date" required>
            </div>
            <div>
                <label for="type">Type of Xperience</label>
                <select v-model="formData.type" id="type" required @change="handleTypeChange">
                    <option value="" disabled>Select Xperience</option>
                    <option value="Discover">Discover</option>
                    <option value="Deep Dive">Deep Dive</option>
                    <option value="VIP">VIP</option>
                    <option value="Sixth Senses">Sixth Senses</option>
                    <option value="Windstar">Windstar</option>
                    <option value="Other">Other</option>
                </select>
                <div v-if="formData.type === 'Other'">
                    <label for="otherType">Please specify</label>
                    <input type="text" v-model="formData.otherType" id="otherType" required>
                </div>
            </div>
            <div>
                <label for="activity">Activity</label>
                <select v-model="formData.activity" id="activity" multiple required @change="handleActivityChange">
                    <option value="" disabled>Select Activity</option>
                    <option value="Presentation">Presentation</option>
                    <option value="Snorkeling">Snorkeling</option>
                    <option value="Ropes Making">Ropes Making</option>
                    <option value="Nursery Cleaning">Nursery Cleaning</option>
                    <option value="Monitoring">Monitoring</option>
                    <option value="Microfragmentation">Microfragmentation</option>
                    <option value="Outplanting">Outplanting</option>
                    <option value="Other">Other</option>
                </select>
                <div v-for="(otherActivity, index) in formData.otherActivities" :key="index">
                    <label :for="'otherActivity' + index">Please specify</label>
                    <input type="text" v-model="formData.otherActivities[index]" :id="'otherActivity' + index" required>
                </div>
            </div>
            <div>
                <label for="adults">Number of adults</label>
                <input type="number" v-model="formData.adults" id="adults" min="0" required>
            </div>
            <div>
                <label for="kids">Number of kids</label>
                <input type="number" v-model="formData.kids" id="kids" min="0" required>
            </div>
            <button class="submit-btn" type="submit">Submit</button>
            <button class="cancel-btn" @click="close">Cancel</button>
        </form>
    </div>
</template>

<script>
import { db } from '@/main.js'; // Adjust the path to your Firebase config file
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { EventBus } from '@/event-bus.js';

export default {
    name: 'XperienceForm',
    props: ['branchId'],
    data() {
        return {
            formData: {
                date: this.getTodayDate(),
                type: '',
                otherType: '', // New field for other type
                activity: [],
                otherActivities: [], // New field for other activities
                adults: 0,
                kids: 0
            },
        };
    },
    mounted() {
        console.log(this)
        console.log('Mounted', this.branchId);
    },
    methods: {
        async handleSubmit() {
            try {
                const timestamp = new Date();
                const formattedTimestamp = this.formatTimestamp(timestamp);
                const docRef = doc(db, `Orgs/coral-gardeners/Branches/${this.branchId}/Xperience/${formattedTimestamp}`);

                // Filter and map other activities
                const activities = this.formData.activity.map(activity =>
                    activity === 'Other' ? this.formData.otherActivities.shift() : activity
                );

                await setDoc(docRef, {
                    date: Timestamp.fromDate(new Date(this.formData.date)), // Store date as Firestore timestamp
                    type: this.formData.type === 'Other' ? this.formData.otherType : this.formData.type,
                    activity: activities,
                    adults: this.formData.adults,
                    kids: this.formData.kids,
                    created_at: Timestamp.fromDate(timestamp), // Storing the actual timestamp
                });

                this.$emit('submit');
                EventBus.$emit('show-toast', { message: 'Save successful!', type: 'success', loading: false });
            } catch (error) {
                EventBus.$emit('show-toast', { message: error, type: 'error', loading: false });
            }
        },
        close() {
            this.$emit('close');
        },
        handleTypeChange(event) {
            if (this.formData.type !== 'Other') {
                this.formData.otherType = '';
            }
        },
        handleActivityChange(event) {
            const otherIndex = this.formData.activity.indexOf('Other');
            if (otherIndex === -1 && this.formData.otherActivities.length > 0) {
                this.formData.otherActivities = [];
            } else if (otherIndex !== -1 && this.formData.otherActivities.length < this.formData.activity.filter(a => a === 'Other').length) {
                this.formData.otherActivities.push('');
            }
        },
        formatTimestamp(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
        },
        getTodayDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
    }
};
</script>

<style scoped>
.form-container {
    background-color: #000;
    color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.form-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.form-title {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
}

input,
select {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #1F1F1F;
    /* Dark gray background */
    border: 1px solid #fff;
    /* White outline */
    border-radius: 0.25rem;
    color: #fff;
    /* White text */
}

.submit-btn {
    background-color: #27bdf4;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.cancel-btn {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

button:hover {
    background-color: #1e9acb;
}
</style>
