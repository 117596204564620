<template>
    <header class="p-3 bg-black flex items-center justify-between border-b border-gray-500 text-lg z-3">
        <!-- Left Side -->
        <div class="flex items-center">
            <!-- Company Logo -->
            <img src="@/assets/images/cg.png" alt="Company Logo" class="h-8 w-8 mr-2 ml-1">

            <div class="inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
            </div>

            <div class="inline-flex items-center cursor-pointer">
                <p class="text-white">Impact Portal</p>
            </div>
        </div>
        <!-- Right Side -->
        <div class="flex items-center text-base">
            <Dropdown v-if="selectedBranch" v-model="selectedBranch" :options="branchOptions" @input="changeBranch" />
            <div class="relative">
                <button @click="toggleDropdown" class="text-white focus:outline-none">
                    <span class="mdi mdi-dots-vertical h-6 w-6 text-2xl ml-2"></span>
                </button>
                <div v-if="dropdownOpen"
                    class="absolute top-full right-0 mt-2 w-36 bg-black border border-neutral-700 rounded shadow-lg z-20">
                    <!-- Dropdown items -->
                    <div class="py-1">
                        <a href="#" class="block px-4 py-2 text-sm text-white" @click="download">
                            <span class="mdi mdi-download mr-2"></span>Download
                        </a>
                        <a href="#" class="block px-4 py-2 text-sm text-white" @click="editGoals">
                            <span class="mdi mdi-pencil mr-2"></span>Edit Goals
                        </a>
                        <a href="#" class="block px-4 py-2 text-sm text-white" @click="logout">
                            <span class="mdi mdi-logout mr-2"></span>Logout
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue';
import { db } from '@/main.js'
import { collection, getDocs } from "firebase/firestore";
import { EventBus } from '@/event-bus';
import * as XLSX from 'xlsx';

export default {
    name: 'Header',
    components: {
        Dropdown
    },
    data() {
        return {
            branches: [],
            selectedBranch: null,
            dropdownOpen: false,
        }
    },
    mounted() {
        this.getBranches();
    },
    computed: {
        branchOptions() {
            return this.branches.map(branch => ({
                name: branch.branch.name,
                id: branch.branch.id
            }));
        },
    },
    methods: {
        async getBranches() {
            const branchesSnapshot = await getDocs(collection(db, `Orgs/coral-gardeners/Branches`));
            for (const branchDoc of branchesSnapshot.docs) {
                if (branchDoc.id.startsWith('sandbox')) continue;
                const branchData = branchDoc.data();
                const branchId = branchDoc.id;

                this.branches.push({
                    branch: { id: branchId, ...branchData },
                });
            }
            this.selectedBranch = this.branchOptions.find(branch => branch.id === 'moorea');
        },
        changeBranch(branch) {
            EventBus.$emit('change-branch', branch);
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        editGoals() {
            this.$emit('edit-goals');
            this.toggleDropdown();
        },
        logout() {
            this.$emit('logout');
            this.toggleDropdown();
        },
        async download() {
            if (!this.selectedBranch) {
                alert("Please select a branch before downloading data.");
                return;
            }

            this.toggleDropdown(); // Close the dropdown after clicking
            const collections = ['Awareness', 'Education', 'Xperience'];
            const workbook = XLSX.utils.book_new();

            for (const collectionName of collections) {
                const sheetData = await this.getCollectionData(collectionName, this.selectedBranch.id);
                if (sheetData.length > 0) {
                    const worksheet = XLSX.utils.json_to_sheet(sheetData);
                    XLSX.utils.book_append_sheet(workbook, worksheet, collectionName);
                }
            }

            XLSX.writeFile(workbook, `${this.selectedBranch.name}_Data.xlsx`);
        },

        async getCollectionData(collectionName, branchId) {
            const data = [];
            const eventsRef = collection(db, `Orgs/coral-gardeners/Branches/${branchId}/${collectionName}`);
            const eventsSnapshot = await getDocs(eventsRef);

            eventsSnapshot.forEach((eventDoc) => {
                const eventData = eventDoc.data();
                const formattedData = {
                    id: eventDoc.id,
                    ...this.formatDocumentData(eventData)
                };
                data.push(formattedData);
            });

            return data;
        },

        formatDocumentData(docData) {
            const formattedData = {};
            for (const [key, value] of Object.entries(docData)) {
                if (key === 'created_at' || key === 'date') {
                    formattedData[key] = this.formatTimestamp(value);
                } else if (Array.isArray(value)) {
                    formattedData[key] = value.join(', ');
                } else if (typeof value === 'object' && value !== null) {
                    // Handle nested objects if necessary
                    formattedData[key] = JSON.stringify(value);
                } else {
                    formattedData[key] = value;
                }
            }
            return formattedData;
        },

        formatTimestamp(timestamp) {
            if (timestamp && timestamp.seconds) {
                const date = new Date(timestamp.seconds * 1000);
                return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD format
            }
            return '';
        },
    },
}
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>