import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import '@mdi/font/css/materialdesignicons.min.css';
import './assets/styles/index.css'


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCoQgZKagwZvTMZ8JFeBcsfKtzn4rF4z3I",
  authDomain: "impact-portal-reefos.firebaseapp.com",
  projectId: "impact-portal-reefos",
  storageBucket: "impact-portal-reefos.appspot.com",
  messagingSenderId: "538217049971",
  appId: "1:538217049971:web:8ae02fb3f92f165b0b1f06",
  measurementId: "G-GEG3WLV8YK"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);
export const db = getFirestore(app);

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) },
}).$mount('#app')
