<template>
    <div>
        <div v-if="!showForm" class="card" @click="handleClick">
            <div class="card-header">
                <span class="card-icon">
                    <img :src="require(`@/assets/icons/${icon}.svg`)" alt="" />
                </span>
                <span class="card-date">{{ date }}</span>
            </div>
            <div class="card-content">
                <p class="card-subtitle">{{ subtitle }}</p>
                <p class="card-number">{{ number }}</p>
            </div>
        </div>
        <XperienceForm v-if="subtitle === 'Xperience' && showForm" @close="close" @submit="submit"
            :branchId="branchId" />
        <AwarenessForm v-if="subtitle === 'Local Awareness' && showForm" @close="close" @submit="submit"
            :branchId="branchId" />
        <EducationForm v-if="subtitle === 'Educational Programs' && showForm" @close="close" @submit="submit"
            :branchId="branchId" />
    </div>
</template>

<script>
import XperienceForm from './XperienceForm.vue';
import AwarenessForm from './AwarenessForm.vue';
import EducationForm from './EducationForm.vue';

export default {
    props: ['icon', 'date', 'subtitle', 'number', 'branchId'],
    components: {
        XperienceForm,
        AwarenessForm,
        EducationForm
    },
    data() {
        return {
            showForm: false,
        };
    },
    methods: {
        handleClick() {
            this.$emit('card-click', this.subtitle);
            this.showForm = !this.showForm;
        },
        submit() {
            this.$emit('submit');
            this.showForm = false;
        },
        close() {
            this.$emit('card-click', null);
            this.showForm = false;
        }
    }
};
</script>

<style scoped>
.card {
    background-color: #000;
    color: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-icon img {
    width: 1.5rem;
    height: 1.5rem;
}

.card-date {
    font-size: 0.75rem;
    color: #999;
}

.card-content {
    margin-top: 1rem;
}

.card-subtitle {
    color: #ccc;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.card-number {
    font-size: 2rem;
    font-weight: bold;
}
</style>
