<template>
    <transition name="fade">
        <div v-if="visible" :class="['toast', type]">
            <div v-if="loading" class="loading-spinner"></div>
            <span>{{ message }}</span>
        </div>
    </transition>
</template>

<script>
import { EventBus } from '@/event-bus.js';

export default {
    data() {
        return {
            visible: false,
            message: '',
            type: '',
            loading: false,
        };
    },
    created() {
        EventBus.$on('show-toast', this.showToast);
    },
    beforeDestroy() {
        EventBus.$off('show-toast', this.showToast);
    },
    methods: {
        showToast({ message, type, duration = 2000, loading = false }) {
            this.message = message;
            this.type = type;
            this.loading = loading;
            this.visible = true;

            if (!loading) {
                setTimeout(() => {
                    this.visible = false;
                }, duration);
            }
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.toast {
    display: flex;
    align-items: center;
    /* Centers items vertically in the container */
    justify-content: center;
    /* Centers the flex container's contents horizontally */
    gap: 10px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    color: #fff;
    border-radius: 25px;
    z-index: 1000;
    background-color: #27bdf4;
}

.success {
    background-color: #3AB749;
}

.error {
    background-color: #FF3A3A;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>