<template>
    <div class="main-container">
        <ToastNotification />
        <Header class="h-12" @edit-goals="showGoalsForm" @logout="handleLogout" />
        <div class="main-content">
            <LeftSidebar v-if="!isMobile" />
            <div class="content-wrapper">
                <div class="content">
                    <div v-if="!showingGoalsForm" :class="{ 'cards-container': !isMobile }">
                        <Card v-for="(card, index) in cards"
                            v-show="expandedCard === card.subtitle || expandedCard === null" :key="index"
                            :branchId="branchId" :icon="card.icon" :date="card.date" :subtitle="card.subtitle"
                            :number="card.number" @card-click="handleCardClick" @submit="handleSubmit" />
                    </div>
                    <GoalsForm v-else :branchId="branchId" @submit="handleGoalsSubmit" @cancel="hideGoalsForm" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/main.js'; 
import { getAuth, signOut } from 'firebase/auth';
import { collection, query, orderBy, limit, getDocs, getCountFromServer } from 'firebase/firestore';
import LeftSidebar from './LeftSidebar.vue';
import Header from './Header.vue';
import Card from './Card.vue';
import GoalsForm from './GoalsForm.vue';
import ToastNotification from './utils/ToastNotification.vue'
import { EventBus } from '@/event-bus';

export default {
    name: 'Main',
    components: {
        LeftSidebar,
        Header,
        Card,
        GoalsForm,
        ToastNotification
    },
    data() {
        return {
            cards: [],
            expandedCard: null,
            branchId: 'moorea', // Default branch ID
            showingGoalsForm: false,
        };
    },
    mounted() {
        this.getData();
        EventBus.$on('change-branch', (branch) => {
            console.log('Branch changed:', branch); 
            this.branchId = branch.id;
            EventBus.$emit('show-toast', { message: `Fetching data for ${this.branchId}...`, type: '', loading: false })
            this.getData();
        });
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        async getData() {
            const categories = [
                { id: 'Xperience', name: 'Xperience', icon: 'earth' },
                { id: 'Awareness', name: 'Local Awareness', icon: 'horn' },
                { id: 'Education', name: 'Educational Programs', icon: 'book' },
            ];
            const updatedCards = [];

            for (const category of categories) {
                const collectionRef = collection(db, `Orgs/coral-gardeners/Branches/${this.branchId}/${category.id}`);

                try {
                    // Fetch the most recent document
                    const recentDocQuery = query(collectionRef, orderBy('date', 'desc'), limit(1));
                    const recentDocSnapshot = await getDocs(recentDocQuery);
                    let mostRecentData = null;

                    recentDocSnapshot.forEach((doc) => {
                        mostRecentData = {
                            date: doc.data().date.toDate().toLocaleDateString(),
                            data: doc.data(),
                        };
                    });

                    const totalDocsSnapshot = await getCountFromServer(collectionRef);
                    const totalDocs = totalDocsSnapshot.data().count;

                    updatedCards.push({
                        icon: category.icon,
                        date: mostRecentData ? mostRecentData.date : '-',
                        subtitle: category.name,
                        number: totalDocs,
                        data: mostRecentData ? mostRecentData.data : null,
                    });
                } catch (error) {
                    EventBus.$emit('show-toast', { message: error, type: 'error', loading: false });
                }
            }

            this.cards = updatedCards;
        },
        handleCardClick(subtitle) {
            this.expandedCard = this.expandedCard === null ? subtitle : null;
        },
        handleSubmit() {
            this.expandedCard = null;
            this.getData();
        },
        showGoalsForm() {
            this.showingGoalsForm = true;
        },
        hideGoalsForm() {
            this.showingGoalsForm = false;
        },
        handleGoalsSubmit() {
            this.hideGoalsForm();
            // Optionally, you can refresh your data here if needed
            this.getData();
        },
        async handleLogout() {
            const auth = getAuth();
            try {
                await signOut(auth);
            } catch (error) {
                console.error("Logout failed: ", error.message);
            }
        }
    },
};
</script>

<style scoped>
.main-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

.main-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -2;
    /* Black background behind everything */
}

.main-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(50px);
    z-index: -1;
    /* Blurred background image above the black background */
}

@media (min-width: 768px) {
    .main-container::before {
        background-size: auto;
        background-repeat: repeat;
    }
}

.main-content {
    display: flex;
    height: calc(100vh - 3rem);
    /* Adjust height to fit the screen minus header */
}

.content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-radius: 1rem;
    padding: 1rem;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
}

.cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 800px;
    margin: auto;
}

.cards-container>* {
    flex: 1 1 calc(33% - 1rem);
    margin: 0.5rem;
}

@media (max-width: 767px) {
    .cards-container {
        flex-direction: column;
    }

    .cards-container>* {
        flex: 1 1 auto;
    }
}
</style>
