<template>
    <div class="form-container">
        <div class="form-header">
            <span class="mdi mdi-flag-checkered text-2xl mr-3 text-primary"></span>
            <h2 class="form-title">EDIT GOALS</h2>
        </div>
        <form @submit.prevent="handleSubmit" v-if="!loading">
            <div v-for="(goal, index) in goals" :key="index">
                <label :for="goal.id">{{ goal.label }}</label>
                <input type="number" v-model.number="goal.value" :id="goal.id" min="0" required>
            </div>
            <button class="submit-btn" type="submit">Submit</button>
            <button class="cancel-btn" @click="cancel">Cancel</button>
        </form>
        <div v-else class="loading">Loading goals...</div>
    </div>
</template>

<script>
import { db } from '@/main.js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default {
    props: {
        branchId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            goals: [
                { id: 'peopleEducated', label: 'People Educated', value: 0 },
                { id: 'kidsEmpowered', label: 'Kids Empowered', value: 0 },
                { id: 'workshops', label: 'Workshops', value: 0 },
                { id: 'educationalPrograms', label: 'Educational Programs', value: 0 },
                { id: 'ecoTours', label: 'Eco Tours', value: 0 }
            ],
            loading: true
        };
    },
    created() {
        this.fetchGoals();
    },
    methods: {
        async fetchGoals() {
            try {
                const branchRef = doc(db, `Orgs/coral-gardeners/Branches/${this.branchId}`);
                const branchDoc = await getDoc(branchRef);

                if (branchDoc.exists()) {
                    const branchData = branchDoc.data();
                    if (branchData.goals) {
                        this.goals = this.goals.map(goal => ({
                            ...goal,
                            value: branchData.goals[goal.id] || 0
                        }));
                    }
                }
            } catch (error) {
                console.error('Error fetching goals:', error);
            } finally {
                this.loading = false;
            }
        },
        async handleSubmit() {
            try {
                const goalsData = {};
                this.goals.forEach(goal => {
                    goalsData[goal.id] = goal.value;
                });

                const branchRef = doc(db, `Orgs/coral-gardeners/Branches/${this.branchId}`);
                await updateDoc(branchRef, { goals: goalsData });

                this.$emit('submit');
            } catch (error) {
                console.error('Error submitting goals:', error);
                alert('Error submitting goals. Please try again.');
            }
        },
        cancel() {
            this.$emit('cancel');
        }
    }
};
</script>

<style scoped>
.form-container {
    max-width: 800px;
    margin: auto;
    background-color: #000;
    color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.form-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.form-title {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
}

input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #1F1F1F;
    border: 1px solid #fff;
    border-radius: 0.25rem;
    color: #fff;
}

.submit-btn {
    background-color: #27bdf4;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.cancel-btn {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

button:hover {
    background-color: #1e9acb;
}
</style>