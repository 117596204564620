<template>
    <div class="form-container">
        <div class="form-header">
            <img src="@/assets/icons/horn.svg" alt="Local Awareness Icon" class="form-icon" />
            <h2 class="form-title">EDUCATIONAL PROGRAM</h2>
        </div>
        <form @submit.prevent="handleSubmit">
            <div>
                <label for="date">Date</label>
                <input type="date" v-model="formData.date" id="date" required>
            </div>
            <div>
                <label for="name">Name of the program</label>
                <input type="text" v-model="formData.name" id="name" required>
            </div>
            <div>
                <label for="activities">Choose activities</label>
                <select v-model="formData.activities" id="activities" multiple required>
                    <option value="ropes making">Ropes Making</option>
                    <option value="seeding nurseries">Seeding Nurseries</option>
                    <option value="monitorings">Monitorings</option>
                    <option value="corals outplanting">Corals Outplanting</option>
                    <option value="algae removal">Algae Removal</option>
                </select>
            </div>
            <div v-if="formData.activities.includes('ropes making')">
                <label for="ropesMakingCorals">Number of corals put in ropes</label>
                <input type="number" v-model="formData.ropesMakingCorals" id="ropesMakingCorals" min="0">
            </div>
            <div v-if="formData.activities.includes('corals outplanting')">
                <label for="coralsOutplanting">Number of corals planted</label>
                <input type="number" v-model="formData.coralsOutplanting" id="coralsOutplanting" min="0">
            </div>
            <div>
                <label for="adults">Number of adults</label>
                <input type="number" v-model="formData.adults" id="adults" min="0" required>
            </div>
            <div>
                <label for="kids">Number of kids</label>
                <input type="number" v-model="formData.kids" id="kids" min="0" required>
            </div>
            <button class="submit-btn" type="submit">Submit</button>
            <button class="cancel-btn" @click="close">Cancel</button>
        </form>
    </div>
</template>

<script>
import { db } from '@/main.js'; // Adjust the path to your Firebase config file
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { EventBus } from '@/event-bus';

export default {
    name: 'EducationForm',
    props: ['branchId'],
    data() {
        return {
            formData: {
                date: this.getTodayDate(),
                name: '',
                activities: [],
                kids: 0,
                adults: 0,
                ropesMakingCorals: 0,
                coralsOutplanting: 0
            },
        };
    },
    methods: {
        async handleSubmit() {
            try {
                const timestamp = new Date();
                const formattedTimestamp = this.formatTimestamp(timestamp);
                const docRef = doc(db, `Orgs/coral-gardeners/Branches/${this.branchId}/Education/${formattedTimestamp}`);

                await setDoc(docRef, {
                    date: Timestamp.fromDate(new Date(this.formData.date)), // Store date as Firestore timestamp
                    name: this.formData.name,
                    activities: this.formData.activities,
                    kids: this.formData.kids,
                    adults: this.formData.adults,
                    ropesMakingCorals: this.formData.ropesMakingCorals,
                    coralsOutplanting: this.formData.coralsOutplanting,
                    created_at: Timestamp.fromDate(timestamp), // Storing the actual timestamp
                });

                this.$emit('submit');
                EventBus.$emit('show-toast', { message: 'Save successful!', type: 'success', loading: false });
            } catch (error) {
                EventBus.$emit('show-toast', { message: error, type: 'error', loading: false });
            }
        },
        close() {
            this.$emit('close');
        },
        formatTimestamp(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
        },
        getTodayDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
    }
};
</script>

<style scoped>
.form-container {
    background-color: #000;
    color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.form-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}

.form-title {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
}

input,
select {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: #1F1F1F;
    /* Dark gray background */
    border: 1px solid #fff;
    /* White outline */
    border-radius: 0.25rem;
    color: #fff;
    /* White text */
}

.submit-btn {
    background-color: #27bdf4;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.cancel-btn {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;

}

button:hover {
    background-color: #1e9acb;
}
</style>
